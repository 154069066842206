import EducationDetails from "../components/education/Education";
import { AboutMeProvider } from "../context/AboutMeContext";
import { motion } from "framer-motion";

const Education = () => {
  return (
    <AboutMeProvider>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, delay: 1 }}
        exit={{ opacity: 0 }}
        className="container mx-auto"
      >
        <EducationDetails />
      </motion.div>
    </AboutMeProvider>
  );
};

export default Education;
