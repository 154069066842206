import { motion } from "framer-motion";
import WorkExperience from "../components/work/Work";
import { AboutMeProvider } from "./../context/AboutMeContext";

const Work = () => {
  return (
    <AboutMeProvider>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          ease: "easeInOut",
          duration: 0.5,
          delay: 0.1,
        }}
        className="container mx-auto flex flex-col-reverse lg:flex-row py-5 lg:py-10 lg:mt-10 items-center"
      >
        <WorkExperience />
      </motion.div>
    </AboutMeProvider>
  );
};

export default Work;
