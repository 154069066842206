import WebImage2 from "../images/web-project-2.jpg";

export const projectsData = [
  {
    id: 1,
    title: "Facade Inspector",
    category: "Mobile Application",
    organization: "H3 Dynamics",
    img: WebImage2,
    ProjectHeader: {
      title: "Project Management UI - From Context",
      publishDate: "Jul 26, 2021",
      tags: "UI / Frontend",
    },
  },
  {
    id: 2,
    title: "Automated University Admissions",
    category: "Web Application",
    organization: "University of Moratuwa",
    img: WebImage2,
  },
  {
    id: 3,
    title: "Converse AI",
    category: "Web Application",
    organization: "Rootcode Labs",
    img: WebImage2,
  },
  {
    id: 4,
    title: "Mint Suites",
    category: "Web Application",
    organization: "Freelance",
    img: WebImage2,
  },
  {
    id: 5,
    title: "Client Project",
    category: "Web Application",
    organization: "Rootcode Labs",
    img: WebImage2,
  },
  {
    id: 6,
    title: "Reimbusement System",
    category: "Web Application",
    organization: "Creative Software",
    img: WebImage2,
  },
  {
    id: 7,
    title: "Pedestrian Protector",
    category: "Micro controller based application",
    organization: "University of Moratuwa",

    img: WebImage2,
  },
];
