export const aboutMeData = [
  {
    id: 1,
    bio: " odit libero tenetur neque consequatur ea.",
  },
  {
    id: 2,
    bio: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nihil vel illum asperiores dignissimos cumque quibusdam et fugiat voluptatem nobis suscipit explicabo, eaque consequatur nesciunt, fugit eligendi corporis laudantium adipisci soluta?",
  },
];

export const educationData = [
  {
    id: 1,
    name: "University of Moratuwa, Sri Lanka",
    duration: "November 2018 - Present",
    education: [
      { course: "Bsc (Hons.) in Information Technology", score: "3.63 / 4.2" },
    ],
  },
  {
    id: 2,
    name: "Nelliady Central College, Sri Lanka",
    duration: "January 2008 - August 2016",
    education: [
      { course: "G.C.E A/L - Physical Science", score: "2A,B" },
      { course: "G.C.E O/L", score: "8A,C" },
    ],
  },
];

export const workData = [
  {
    id: 1,
    name: "H3 Dynamics, Singapore",
    duration: "November 2022 - Present",
    role: "Software Engineer",
    description: "Worked in abc d projects",
    technologies: "React, React Native, Redux, Firebase",
  },
  {
    id: 2,
    name: "Rootcode Labs, Sri Lanka",
    duration: "January 2022 - July 2022",
    role: "Intern Software Engineer",
    description: "Worked in abc d projects",
    technologies: "React, Redux, Tailwind CSS, Firebase, Sockets, FastAPI",
  },
];
