import { useState, createContext } from "react";
import { aboutMeData, educationData, workData } from "../data/aboutMeData";

const AboutMeContext = createContext();

export const AboutMeProvider = ({ children }) => {
  const [aboutMe, setAboutMe] = useState(aboutMeData);
  const [education, setEducation] = useState(educationData);
  const [work, setWork] = useState(workData);

  return (
    <AboutMeContext.Provider
      value={{
        aboutMe,
        setAboutMe,
        education,
        setEducation,
        work,
        setWork,
      }}
    >
      {children}
    </AboutMeContext.Provider>
  );
};

export default AboutMeContext;
