import { useContext } from "react";
import AboutMeContext from "../../context/AboutMeContext";

const Education = () => {
  const { education } = useContext(AboutMeContext);
  return (
    <div className="w-full lg:w-1/2">
      <div className="text-left max-w-xl px-6">
        <h2 className="font-general-medium text-2xl text-primary-dark dark:text-primary-light mt-20 mb-8">
          Education
        </h2>
        <div className="font-general-regular w-full sm:w-3/4 text-left">
          {education.map((e) => (
            <div
              className="mb-4 text-ternary-dark dark:text-ternary-light text-lg"
              key={e.id}
            >
              <div>{e.name}</div>
              <div>{e.duration}</div>
              {e.education.map((edu) => (
                <div key={edu.course} className="flex flex-row">
                  <div className="pr-1">{edu.course} :</div>
                  <div>{edu.score}</div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Education;
