import { useContext } from "react";
import AboutMeContext from "../../context/AboutMeContext";

const Work = () => {
  const { work } = useContext(AboutMeContext);

  return (
    <div className="w-full lg:w-1/2">
      <div className="text-left max-w-xl px-6">
        <h2 className="font-general-medium text-2xl text-primary-dark dark:text-primary-light mt-2 mb-8">
          Work Experience
        </h2>

        <div className="font-general-regular w-full sm:w-3/4 text-left">
          {work.map((w) => (
            <div
              className="mb-4 text-ternary-dark dark:text-ternary-light text-lg"
              key={w.id}
            >
              <div>{w.name}</div>
              <div>{w.duration}</div>
              <div>{w.role}</div>
              {/* <div>{w.description}</div>
              <div>{w.technologies}</div> */}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Work;
